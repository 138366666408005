import React from "react";
import Layout from "../components/layout";

const ContactPage = () => {
    return (
        <Layout>
            <h1>Contact Page</h1>
        </Layout>
    )
}

export default ContactPage