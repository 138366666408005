import React from "react";
import {Link} from "gatsby"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";

const Header = () => {
    return (
        <nav className="hero-head">
            <div className="columns is-mobile is-marginless heading has-text-weight-bold">
                <div className="column left desktop">
                    <Link to="/profili"><p className="navbar-item has-text-black">Profili</p></Link>
                    <p className="navbar-item has-text-black">Ograde</p>
                    <p className="navbar-item has-text-black">Usluge</p>
                </div>
                <div className="column right">
                    <p className="navbar-item has-text-black desktop">Kontakt</p>
                    <p className="navbar-item has-text-black desktop">Lokacija</p>
                    <figure className="navbar-item image center">
                        <FontAwesomeIcon icon={faBars} style={{height: "1rem"}}/>
                    </figure>
                </div>
            </div>
        </nav>
    )
}

export default Header